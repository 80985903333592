<template>
    <v-main>
        <v-container>
            <div class="text-h4 mb-4">Workspace Admin</div>
            <!-- <div v-if="loading">
                <v-skeleton-loader class="mb-4" type="paragraph" v-for="n in 10" :key="n" />
            </div> -->
            <div>
            <SearchPeople
                ref="sPeople"
                v-model="foundUser"
                label="Search people"
                :show-loader="false"
                users-only
                ldap-populate
                dense
                rounded
                outlined
            />
            </div>
            <div v-if="loading">
                <v-row class="fill-height text-center" align-content="center" justify="center" style="height: 200px">
                    <v-col cols="8">
                        <div class="text-h6 font-weight-light">Loading...</div>
                        <v-progress-linear
                            indeterminate
                            rounded
                            height="3"
                        ></v-progress-linear>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-4" v-else-if="Object.keys(workspaces).length > 0">
                <v-row>
                    <v-card
                        elevation="2"
                        outlined
                        width="350"
                        class="mx-2 my-2 mx-auto"
                        v-for="curWorkspace in workspaces"
                        :key="curWorkspace.UserName"
                        :loading="workspaceLoading.includes(curWorkspace.WorkspaceId)"
                    >
                        <v-card-title>
                            {{ curWorkspace.ComputerName }}
                            <v-icon small class="ml-2" :color="getStatusColor(curWorkspace.State)">mdi-checkbox-blank-circle</v-icon>
                            <v-row align="center" justify="end">
                                <v-btn @click="refreshWorkspace(curWorkspace)" icon class="ml-2 ">
                                    <v-icon small>mdi-refresh</v-icon>
                                </v-btn>
                                <v-menu
                                    bottom
                                    right
                                >
                                    <template v-slot:activator="{on, attrs}">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            class="mr-2"
                                        >
                                        <v-icon small>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list
                                        dense
                                    >
                                        <v-list-item @click="rebootWorkspace(curWorkspace)">
                                            <v-list-item-title>Reboot</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="restoreWorkspace(curWorkspace)">
                                            <v-list-item-title>Restore</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-row>
                            
                        </v-card-title>
                        <v-card-text>
                            <div>User: {{ curWorkspace.UserName }}</div>
                            <div>Status: {{ curWorkspace.State }}</div>
                            <div>IP: {{ curWorkspace.IpAddress }}</div>
                            <div>Compute Type: {{ curWorkspace.WorkspaceProperties.ComputeTypeName }}</div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-spacer />
                            <v-btn @click="removeWorkspace(curWorkspace)" icon><v-icon small>mdi-close</v-icon></v-btn>
                        </v-card-actions>
                    </v-card>
                </v-row>
            </div>
        </v-container>
        <ConfirmDialog
            ref="confirmDlg"
            :title="confirmDlgText"
            confirm-button-text="Confirm"
            confirm-button-icon="mdi-check"
            @confirm="confirmAction()"
        />
    </v-main>
</template>

<script>
import SearchPeople from '@/components/population/SearchPeople';
import ConfirmDialog from '@/components/common/ConfirmDialog';
import { computed, onBeforeUnmount, ref, watch } from '@vue/composition-api';

export default {
    components: {
        SearchPeople,
        ConfirmDialog
    },
    setup(props, { root }) {
        
        const foundUser = ref(null);

        const userList = ref([]);

        const store = root.$store;
        
        const loading = ref(false);
        
        const sPeople = ref(null);

        const confirmDlgText = ref('');
        const confirmDlg = ref(null);

        const workspaceAction = ref({
            workspace: null,
            action: ''
        });

        const workspaceLoading = ref([]);

        const rebootWorkspace = (workspace) => {
            confirmDlgText.value = "Are you sure you want to reboot this workspace?"
            confirmDlg.value.show();
            workspaceAction.value.action = 'reboot';
            workspaceAction.value.workspace = workspace;
        }

        const restoreWorkspace = (workspace) => {
            confirmDlgText.value = "Are you sure you want to restore this workspace?"
            confirmDlg.value.show();
            workspaceAction.value.action = 'restore';
            workspaceAction.value.workspace = workspace;
        }

        const removeWorkspace = (curWorkspace) => {
            store.dispatch('workspaceAdmin/removeWorkspaceByUni', curWorkspace.UserName);
        }

        const confirmAction = async () => {
            let action = workspaceAction.value.action;
            let workspace = workspaceAction.value.workspace;
            
            if (action == 'restore') {
                await store.dispatch('workspaceAdmin/restoreWorkspaceById', workspace.WorkspaceId);
            } else {
                await store.dispatch('workspaceAdmin/rebootWorkspaceById', workspace.WorkspaceId);
            }

            resetConfirmDlg();
        }

        const refreshWorkspace = async (workspace) => {
            let user = workspace.UserName;
            workspaceLoading.value.push(workspace.WorkspaceId);
            await store.dispatch('workspaceAdmin/getWorkspaceByUni', user);
            workspaceLoading.value = workspaceLoading.value.filter((item) => item !== workspace.WorkspaceId);
        }

        const resetConfirmDlg = () => {
            workspaceAction.value = {
                action: '',
                workspace: null
            }
        }
        // const curWorkspace = computed (() => store.getters['workspaceAdmin/getCurWorkspace']);
        const workspaces = computed(() => store.getters['workspaceAdmin/getWorkspaces']);
        
        watch(foundUser, async (val) => {
            if (!val) return;
            loading.value = true;
            await store.dispatch('workspaceAdmin/getWorkspaceByUni', val.cu_uni)
            userList.value.push(val)
            sPeople.value.clear();
            loading.value = false;
        });
        
        const getStatusColor = (status) => {
            // let status = curWorkspace.State;
            // console.log(status);
            let color = '';
            switch (status) {
                case 'AVAILABLE':
                    color = 'green'
                    break;
                case 'STOPPED':
                case 'ERROR':
                case 'TERMINATED':
                case 'UNHEALTHY':	
                    color = 'red';
                    break;
                default: 
                    color = 'yellow';
                    break;
            }

            return color;
        };

        onBeforeUnmount(() => {
            store.dispatch('workspaceAdmin/reset');
        })


        return {
            loading,
            foundUser,
            // curWorkspace,
            getStatusColor,
            sPeople,
            workspaces,
            rebootWorkspace,
            restoreWorkspace,
            confirmDlgText,
            confirmDlg,
            confirmAction,
            refreshWorkspace,
            workspaceLoading,
            removeWorkspace
        }
    },
};
</script>

<style scoped>
.v-list-item-title {
    font-size: 8px;
}
</style>